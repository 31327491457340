<template>
  <v-container style="margin-bottom: 200px !important;">
    <!-- Select Transaction Type Dropdown -->
    <v-row>
      <v-col class="py-0">
        <v-select
          v-model="selectedSection"
          :items="transactionTypes"
          label="Select to Buy or Sell"
          @change="resetForms"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Display the selected section based on the dropdown value -->
    <v-row v-if="selectedSection">
      <v-card class="pa-0" elevation="12">
        <v-card-text>
          <v-col>
            <v-form v-if="selectedSection === 'Buy Bread'" ref="depositForm">
              <!-- Deposit Section -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="depositAmount"
                    label="Amount of Bread"
                    :style="{ fontSize: '30px' }"
                    :rules="depositRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn :disabled="!$refs.depositForm || !$refs.depositForm.validate() || submitting" @click="submitDeposit" class="full-width-btn primary">
                    <template v-if="submitting">BUYING...</template>
                    <template v-else>
                      Buy Bread
                      <v-icon right>mdi-chevron-right</v-icon>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-form v-else-if="selectedSection === 'Sell Bread'" ref="withdrawalForm" @submit.prevent="submitWithdrawal">
              <!-- Withdrawal Section -->
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field 
                    v-model="withdrawalAmount"
                    label="Amount of bread" 
                    :style="{ fontSize: '30px' }"
                    :rules="amountRules"
                    @input="formatWithdrawCurrency"
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-select 
                    v-model="selectedCountry"
                    :items="countries"
                    item-text="name"
                    item-value="value"
                    label="Select Currency"
                    :rules="selectionRules"></v-select>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-select 
                    v-model="selectedBank"
                    :items="banks"
                    item-text="name"
                    item-value="code"
                    label="Select Bank"
                    @change="updateSelectedBank"
                    :rules="selectionRules"></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="accountNumber" label="Account Number" :rules="numberRules" @input="checkAccountNumber"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="accountName" label="Account Name" :rules="textRules" disabled="disabled"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- First Column (Transaction Fee) -->
                <v-col cols="3" class="py-0">
                  <div class="d-flex justify-between" style="font-size: 14px !important">
                    <span class="text-left">Charges: </span>
                    <span class="text-right">{{ withdrawCharges }}</span>
                  </div>
                </v-col>

                <!-- Second Column (Total Fee) -->
                <v-col cols="12" class="py-0">
                  <div class="d-flex justify-between " style="font-size: 20px !important; ">
                    <span class="text-left">Total:&nbsp;</span>
                    <span class="text-right text-bold">{{ parseFloat(totalWithdraw).toLocaleString('en-US') }} pcs</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="py-0">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :type="showPassword ? 'text' : 'password'"
                    v-model="accountPassword"
                    :rules="passRules"
                    label="Your Password"
                    append-icon="mdi-eye"
                    @click:append="togglePasswordVisibility"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-btn 
                    :disabled="!$refs.withdrawalForm || !$refs.withdrawalForm.validate() || submitting"
                    type="submit"
                    class="primary full-width-btn">
                    <template v-if="submitting">SELLING...</template>
                    <template v-else>
                      SELL YOUR BREAD
                      <v-icon right>mdi-chevron-right</v-icon>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-alert v-if="error" type="error" color="red">{{ errorMessage }}</v-alert>

            <v-form v-else-if="selectedSection === 'Message'" ref="messageForm">
              <!-- Message Section -->
              <v-row>
                <v-col cols="12" sm="12" style="word-break: break-word !important;">
                  <v-card class="result-card" :class="{ 'success': transactionStatus === 'success', 'failure': transactionStatus === 'failed' }">
                    <v-icon class="result-icon">{{ transactionStatus === 'success' ? 'mdi-check' : 'mdi-alert' }}</v-icon>
                    <v-card-title class="result-title">{{ transactionStatus === 'success' ? 'Successful Transaction' : 'Failed Transaction' }}</v-card-title>
                    <v-card-subtitle class="result-subtitle">{{ transactionStatus === 'success' ? successMessage : 'Transaction failed. Please try again.' }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-btn @click="navigateToHome" class="full-width-btn">
                    Continue with a Chanllenge
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      submitting: false,
      showPassword: false,
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      previousSection: '',
      selectedSection: '',
      depositAmount: '',
      withdrawalAmount: '',
      selectedCountry: 'NGN',
      selectedBank: '',
      selectedBankName: '',
      accountNumber: '',
      accountName: '',
      accountPassword: '',
      withdrawCharges: 0,
      totalWithdraw: 0,
      countries: [{ name: 'Nigeria', value: 'NGN' }],
      banks: [],
      transactionTypes: ['Buy Bread', 'Sell Bread'],
      showMessageSection: false,
      transactionStatus: '',
      numberRules: [
        (v) => !!v || 'This field is required',
        (v) => /^[0-9]+$/.test(v) || 'Only numbers are allowed'
      ],
      amountRules: [
        (v) => !!v || 'This field is required',
        (v) => /^[0-9,.]+$/.test(v) || 'Only numbers are allowed'
      ],
      depositRules: [
        (v) => !!v || 'This field is required',
        (v) => /^[0-9]+$/.test(v) || 'Only numbers are allowed',
        (v) => (parseFloat(v.replace(/,/g, '')) >= 500) || 'Amount must be at least 500'
      ],
      textRules: [
        (v) => !!v || 'This field is required'
      ],
      passRules: [
        (v) => !!v || 'This field is required'
      ],
      selectionRules: [
        (v) => !!v || 'This field is required'
      ]
    }
  },
  watch: {
    accountNumber(newVal) {
      // Check if the length of the entered account number is 10
      if (newVal.length === 10) {
        if (this.selectedBank.length > 2) {
          // Trigger your fetch request here
          this.verifyAccountNumber()

          if (this.withdrawalAmount.length > 2) {
            this.checkTransferFee()
          }
        }
      }
    }
  },
  // Fetch user information when the component is created
  created() {
    //alert('o boy')
    this.GetCountryBanks()
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    updateSelectedBank(selectedValue) {
      // Update the variables with the selected bank code and name
      this.selectedBank = selectedValue
      const selectedBankObject = this.banks.find((bank) => bank.code === selectedValue)
      
      this.selectedBankName = selectedBankObject ? selectedBankObject.name : ''
    },
    validateAndConvertToNumber(value) {
      // Convert value to string if it's a number
      const stringValue = typeof value === 'number' ? value.toString() : value

      // Remove commas before parsing
      const cleanedValue = stringValue.replace(/,/g, '')

      const parsedAmount = parseFloat(cleanedValue)

      if (isNaN(parsedAmount)) {
        throw new Error('Invalid amount value')
      }

      return parsedAmount
    },
    /* validateAndConvertToNumber (value) {
      // Remove commas before parsing
      const cleanedValue = value.replace(/,/g, '')

      const parsedAmount = parseFloat(cleanedValue)

      if (isNaN(parsedAmount)) {
        throw new Error('Invalid amount value')
      }

      return parsedAmount
    }, */
    formatWithdrawCurrency() {
      // Remove non-numeric characters
      let numericValue = this.withdrawalAmount
        .replace(/[^\d.]/g, '') // Remove non-numeric characters
        .replace(/(\.\d*)\./g, '$1') // Remove consecutive commas

      if (numericValue === '' || isNaN(parseFloat(numericValue))) {
        // Handle the case where the input is empty or NaN
        this.withdrawalAmount = ''
      } else {
        // Format with commas
        numericValue = parseFloat(numericValue).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })

        // Update the model with the formatted value
        this.withdrawalAmount = numericValue
      }
      this.accountNumber = ''
      //this.totalWithdraw = this.validateAndConvertToNumber(this.withdrawCharges) + this.validateAndConvertToNumber(this.withdrawalAmount)
    },
    checkAccountNumber() {
      this.error = false
      this.accountName = ''

    },
    async checkTransferFee() {
      if (this.withdrawalAmount.length > 2) {
        const response = await this.$store.dispatch('wallet/fetchTransferFee', {
          amount: this.validateAndConvertToNumber(this.withdrawalAmount)
        })

        if (response && response.success) {
          if (response.status === 'success') {
            ////console.log('our transfer fee resoibse', response)
            this.withdrawCharges = response.data.fee
            this.totalWithdraw = this.withdrawCharges + this.validateAndConvertToNumber(this.withdrawalAmount)

            ////console.log('our transfer fee is ', response.data.fee)
          }
        }
      }
    },

    async verifyAccountNumber() {
      
      const response = await this.$store.dispatch('wallet/verifyAccountNumber', {
        account_number: this.accountNumber.toString(),
        account_bank: this.selectedBank.toString()
      })

      if (response && response.success) {
        const v_status = response.verified_status

        if (v_status.status === 'success') {
          this.accountName = v_status.data.account_name
          ////console.log('Our account details', v_status)
          this.error = false

        } else {
          this.error = true
          this.accountName = ''
          if (v_status.verified_status) {
            this.errorMessage = v_status.verified_status.message
          }

          if (v_status.data) {
            this.errorMessage = v_status.data.message
          }
          //////console.log('Our account details', v_status)
        }

      } else {
        // Handle error response
        ////console.log('ERROR ! ', response)
        this.error = true
        this.accountName = ''
        if (response.verified_status) {
          this.errorMessage = response.verified_status.message
        }

        if (response.data) {
          this.errorMessage = response.data.message
        }
      }

    },
    async GetCountryBanks() {
      ////console.log('we are here as ')

      const response = await this.$store.dispatch('wallet/fetchCountryBanks')
      
      if (response && response.error) {
        ////console.log('Seems there is an erro', response)

      } else {
        // Handle error response
        this.banks = response.banks.data
          .slice()
          .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        ////console.log('or banks is here ', response.banks.data)
      }
    },
    resetForms() {
      this.depositAmount = ''
      this.withdrawalAmount = ''
      this.selectedCountry = ''
      this.selectedBank = ''
      this.accountNumber = ''
      this.accountName = ''
    },
    
    // This method simulates a successful or failed transaction
    simulateTransaction(t_status) {
      this.previousSection = this.selectedSection

      setTimeout(() => {
        this.showMessageSection = true

        // Set selectedSection to 'Message' after a successful transaction
        if (t_status) {
          this.selectedSection = 'Message'
          this.transactionStatus = 'success'
        } else {
          this.selectedSection = 'Message'
          this.transactionStatus = 'failed'
          //this.selectedSection = this.previousSection
        }
      }, 1000)
    },

    async submitDeposit() {
      this.submitting = true
      try {
        const credentials = {
          amount: this.validateAndConvertToNumber(this.depositAmount),
          userProfile: this.$store.getters['auth/userInfo']
        }
        // Dispatch the action defined in your Vuex store module
        const ourdata = await this.$store.dispatch('wallet/submitDeposit', credentials)

        ////console.log('our data is here ', ourdata)
        if (ourdata && ourdata.success) {
          //////console.log( our data)
          if (ourdata.redirectUrl) {
            // Redirect the user to the specified URL
            window.location.href = ourdata.redirectUrl
          }

        } else {
          // Handle error response
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'black' // Optional, defaults to 'info'
          })
          ////console.log('ERROR ! ', ourdata)
          ////console.log('snackbar show')
          this.submitting = false
          
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        //this.submitting = false
      }
    },

    async submitWithdrawal() {

      this.submitting = true

      try {
        //const failure = false
        
        //this.simulateTransaction(failure)
        if (this.selectedBank.length > 2) {
          try {
            // Call your actual API for withdrawal
            const credentials = {
              amount: this.validateAndConvertToNumber(this.withdrawalAmount),
              transferfee: this.validateAndConvertToNumber(this.withdrawCharges),
              country: this.selectedCountry,
              bank: this.selectedBank,
              accountNumber: this.accountNumber,
              accountName: this.accountName,
              bankName: this.selectedBankName,
              userProfile: this.$store.getters['auth/userInfo'],
              accountPassword: this.accountPassword
            }
            const response = await this.$store.dispatch('wallet/withdrawalRequest', credentials)

            if (response && response.success) {
              ////console.log('Withdrawal requst response is ', response)
              
              this.successMessage = response.message

              this.simulateTransaction(true)
              
              this.$store.dispatch('snackbar/showSnackbar', {
                message: response.message,
                variant: 'success' // Optional, defaults to 'info'
              })
              
              /* const v_status = response.verified_status

              if (v_status.status === 'success') {
                this.accountName = v_status.data.account_name
                ////console.log('Our account details', v_status)
                this.error = false

              } else {
                this.error = true
                this.accountName = ''
                if (v_status.verified_status) {
                  this.errorMessage = v_status.verified_status.message
                }

                if (v_status.data) {
                  this.errorMessage = v_status.data.message
                }
                //////console.log('Our account details', v_status)
              } */

            } else {
              // Handle error response
              this.$store.dispatch('snackbar/showSnackbar', {
                message: response.message,
                variant: 'black' // Optional, defaults to 'info'
              })
              ////console.log('ERROR ! ', response)
              ////console.log('snackbar show')
              
            }
            // Assuming the API returns a success flag
            //const success = response.data.success;

            // Simulate the transaction
            //const success = false
            
            //this.simulateTransaction(success)
          } catch (error) {
            console.error('Error submitting withdrawal:', error)
            // Handle the error
          } 
        } else {
          this.$store.dispatch('snackbar/showSnackbar', {
            message: 'Select your preferred bank.',
            variant: 'black' // Optional, defaults to 'info'
          }) 
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        this.submitting = false
      } 
    },

    navigateToHome() {
      // Redirect to the wallet page
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>
.full-width-btn {
  width: 100%;
}
.result-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  max-width: 400px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.success {
  border: 2px solid #4caf50; /* Green border for success */
  color: #4caf50; /* Green text for success */
  background-color: white !important;
}

.failure {
  border: 2px solid #f44336; /* Red border for failure */
  color: #f44336; /* Red text for failure */
}

.result-icon {
  font-size: 48px;
  margin-bottom: 16px; /* Spacing between the icon and title */
}

.result-title {
  font-size: 20px;
}

.result-subtitle {
  font-size: 16px;
  margin-top: 8px;
}
</style>
